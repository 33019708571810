import { customAxios as axios, isAuthError } from '@autone/ui';
import { CORE_AXIOS_PATH } from '@autone/utils';
//TODO: should be migrated to rtk query
export async function getUserConfig() {
  try {
    const res = await axios(CORE_AXIOS_PATH).get('/users/me');
    return res;
  } catch (err: unknown) {
    if (isAuthError(err)) {
      return {};
    }
    throw err;
  }
}
